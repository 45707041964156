// components/navbar.tsx
import React, { useState, useEffect } from 'react';
import logo from '../assets/logo-nobg/2.png';
import { FcLeftUp2, FcPhoneAndroid } from "react-icons/fc";

function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [contactButtonColor, setContactButtonColor] = useState('text-white');
  

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScroll(isScrolled);
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContactButtonColor(contactButtonColor === 'text-white' ? 'bg-yellow-400' : 'text-white');
    }, 2000); // Altera a cada 2 segundos

    return () => clearTimeout(timer);
  }, [contactButtonColor]);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav className={`fixed top-0 left-0 right-0 z-10 p-4 ${scroll ? 'bg-[#0d0521] bg-opacity-90 shadow-lg' : ''}`} >
      <div className="container mx-auto flex justify-between items-center">
        <img src={logo} alt="Logo" width={ !scroll ? 180 : 100} height={!scroll ? 180 : 100} />
        <ul className={`flex space-x-12 ${scroll ? 'hidden' : ''}`}>
          <li><button onClick={() => scrollToSection("header")} className=" hidden md:block text-white py-2 px-4focus:outline-none">Início</button></li>
          <li><button onClick={() => scrollToSection("services")} className="hidden md:block text-white py-2 px-4focus:outline-none">Serviços</button></li>
          <li><button
              className="focus:outline-none flex items-center text-white bg-green-500 py-2 px-4 rounded-md animate-pulse"
              onClick={() => scrollToSection("contact")}
            >
              Entre em contato <FcPhoneAndroid className="text-2xl ml-2" />
            </button></li>
        </ul>
      </div>
      {scroll && (
        <button onClick={scrollToTop} className="fixed bottom-4 right-4 bg-white text-blue-900 py-4 px-4 rounded-full shadow-md focus:outline-none">
          <FcLeftUp2 className='text-2xl' />
        </button>
      )}
    </nav>
  );
}

export default Navbar;
