// components/FeaturesSection.js
import React from "react";

import SiteImage from "../assets/images/site_development.png";
import CellImage from "../assets/images/cell_development.png";
import BigDataImage from "../assets/images/bigdata.png";
import PeopleProjectImage from "../assets/images/people_project.png";
import ManagementImage from "../assets/images/management.png";
import { GiTeamIdea } from "react-icons/gi";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { MdDeveloperMode } from "react-icons/md";
import { GiTeamDowngrade } from "react-icons/gi";
import { PiHeadCircuitThin } from "react-icons/pi";
import { GrCpu } from "react-icons/gr";

const handleEnviarClick = (tag: string) => {
  let finalMessage = `Olá, gostaria de informações sobre ${tag} `;

  window.open(
    `https://api.whatsapp.com/send/?phone=559231900206&text=${finalMessage}&type=phone_number&app_absent=0`
  );
};

function FeaturesSection() {
  return (
    <section id="services" className="bg-white px-12 py-20">
      <div className="container mx-auto h-auto">
        <h2 className="text-3xl font-semibold mb-4 text-center">
          Chegou a <span style={{ color: "#FF3B61" }}>sua</span> hora de se
          tornar <span style={{ color: "#FF3B61" }}>referência</span> no mercado
        </h2>

        <h2
          className="text-3xl font-semibold mb-12 text-center"
          style={{ color: "#36A7FF" }}
        >
          Veja como podemos elevar o nível da sua empresa
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl p-6">
            {/* Cabeçalho */}
            <div className="flex items-center">
              <span className="text-green-500 font-semibold text-sm">
                ● PROFISSIONAIS ESPECIALIZADOS
              </span>
            </div>

            {/* Título */}
            <div className="my-4">
              <h1 className="text-3xl font-bold text-blue-600">
                Querendo aumentar{" "}
              </h1>
              <p className="text-2xl font-light text-gray-800">a equipe?</p>
            </div>

            {/* Bloco de Recrutamento de TI */}
            <div className="flex items-start my-6">
              <div className="mr-4">
                <GiTeamDowngrade className="text-5xl" />
              </div>
              <div>
                <h2 className="text-xl font-semibold">Recrutamento de TI</h2>
                <p className="text-gray-600">
                  Preencha suas vagas em tempo recorde com nossa base de profissionais
                </p>
                <a
                  href="#"
                  className="text-blue-600 hover:text-blue-800 mt-2 inline-flex items-center"
                  onClick={() => handleEnviarClick('recrutamento')}
                >
                  Saiba mais
                  <svg
                    className="ml-1 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Bloco de Outsourcing de TI */}
            <div className="flex items-start">
            <div className="mr-4">
                <GiTeamIdea className="text-5xl" />
              </div>
              <div>
                <h2 className="text-xl font-semibold">Outsourcing de TI</h2>
                <p className="text-gray-600">
                  Precisando de uma mão extra? Alocamos o profissional certo para você
                </p>
                <a
                  href="#"
                  className="text-blue-600 hover:text-blue-800 mt-2 inline-flex items-center"
                  onClick={() => handleEnviarClick('outsourcing de TI')}
                >
                  Saiba mais
                  <svg
                    className="ml-1 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl p-6">
            {/* Cabeçalho */}
            <div className="flex items-center">
              <span className="text-green-500 font-semibold text-sm">
                ● SOFTWARES PREMIUMS
              </span>
            </div>

            {/* Título */}
            <div className="my-4">
              <h1 className="text-3xl font-bold text-blue-600">
                Site? Aplicativo? CRM?
              </h1>
              <p className="text-2xl font-light text-gray-800">Conte com a gente</p>
            </div>

            {/* Bloco de Recrutamento de TI */}
            <div className="flex items-start my-6">
              <div className="mr-4">
              <HiOutlineComputerDesktop className="text-5xl" />
              </div>
              <div>
                <h2 className="text-xl font-semibold">Plataformas Web</h2>
                <p className="text-gray-600">
                  Plataformas robustas, seguras e de alta qualidade <br/> Feitos sob-demanda para seu negócio
                </p>
                <a
                  href="#"
                  className="text-blue-600 hover:text-blue-800 mt-2 inline-flex items-center"
                  onClick={() => handleEnviarClick('plataformas Web')}
                >
                  Saiba mais
                  <svg
                    className="ml-1 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Bloco de Outsourcing de TI */}
            <div className="flex items-start">
            <div className="mr-4">
                <MdDeveloperMode className="text-5xl" />
              </div>
              <div>
                <h2 className="text-xl font-semibold">iOS & Android</h2>
                <p className="text-gray-600">
                Transforme sua visão em aplicativos inovadores e intuitivos, proporcionando mobilidade e praticidade aos seus usuários.
                </p>
                <a
                  href="#"
                  className="text-blue-600 hover:text-blue-800 mt-2 inline-flex items-center"
                  onClick={() => handleEnviarClick('desenvolvimento para iOS & Android')}
                >
                  Saiba mais
                  <svg
                    className="ml-1 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl p-6">
            {/* Cabeçalho */}
            <div className="flex items-center">
              <span className="text-green-500 font-semibold text-sm">
                ● IA & SUA EMPRESA
              </span>
            </div>

            {/* Título */}
            <div className="my-4">
              <h1 className="text-3xl font-bold text-blue-600">
                Inteligência Artificial
              </h1>
              <p className="text-2xl font-light text-gray-800">Elevando seu negócio</p>
            </div>

            {/* Bloco de Recrutamento de TI */}
            <div className="flex items-start my-6">
              <div className="mr-4">
              <PiHeadCircuitThin className="text-5xl" />
              </div>
              <div>
                <h2 className="text-xl font-semibold">Assistente pessoal</h2>
                <p className="text-gray-600">
                  Tenha seu próprio assistente, deixe-o fazer atendimentos, tirar dúvidas e muito mais
                </p>
                <a
                  href="#"
                  className="text-blue-600 hover:text-blue-800 mt-2 inline-flex items-center"
                  onClick={() => handleEnviarClick('assistente pessoal IA')}
                >
                  Saiba mais
                  <svg
                    className="ml-1 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Bloco de Outsourcing de TI */}
            <div className="flex items-start">
            <div className="mr-4">
                <GrCpu className="text-5xl" />
              </div>
              <div>
                <h2 className="text-xl font-semibold">Modelos prontos para uso</h2>
                <p className="text-gray-600">
                  Utilize modelos prontos para analisar, converter e extrair informações de diversas fontes
                </p>
                <a
                  href="#"
                  className="text-blue-600 hover:text-blue-800 mt-2 inline-flex items-center"
                  onClick={() => handleEnviarClick('modelos prontos de IA')}

                >
                  Saiba mais
                  <svg
                    className="ml-1 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;

export {};
