import { useState } from "react";
import HeroImage from "../assets/images/purple-background.png";
import LottieJson from "../assets/images/lottie.json";
import Lottie from "lottie-react";

export default function Cover() {
  function scrollToContact() {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <header
      id="header"
      className="text-white py-36 px-8 header"
      style={{
        backgroundImage: `url(${HeroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
        <div
          className="container mx-auto md:flex"
        >
          <div style={{alignContent: 'center'}}>
            <p className="md:text-5xl font-bold mb-8 w-4/5">
              Levamos <span style={{ color: "#FF3B61" }}>sua empresa</span> ao
              próximo nível com soluções{" "}
              <span style={{ color: "#FFA07A" }}>tecnológicas inovadoras</span>
            </p>
            <p
              style={{ color: "#F2F2F2" }}
              className="text-2xl font-semibold mb-8 w-2/3"
            >
              Tenha o software sob medida para atender o seu negócio.
            </p>
            <div>
              <button
                onClick={scrollToContact}
                className="bg-white text-indigo-700 py-2 px-6 rounded-full font-semibold hover:bg-indigo-700 hover:text-white transition duration-300"
              >
                Descubra Como
              </button>
            </div>
          </div>
          <Lottie animationData={LottieJson} />
        </div>
      <div className="absolute inset-x-0 bottom-0 h-16 overflow-hidden pointer-events-none">
        <svg
          className="w-full h-full"
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 120 C150 30 350 180 500 120 L500 150 L0 150 Z"
            fill="#ffffff"
          />
        </svg>
      </div>
    </header>
  );

  return <></>;
}
